import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppRoutes} from '../../app-routing.model';
import {BlogItem} from '../../ngrx-store/components/blogitem/blogitem.model';
import {Subscription} from 'rxjs';
import {BlogItemService} from "../../services/blogitem.service";

@Component({
  selector: 'app-blogitem',
  templateUrl: './blogitem.component.html',
  styleUrls: ['./blogitem.component.scss']
})
export class BlogitemComponent implements OnInit, OnDestroy {
  public blogItem: BlogItem | undefined = undefined;

  public AppRoutes = AppRoutes;
  private subscriptions = new Subscription();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private blogitemService: BlogItemService) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.blogitemService.getBlogItemById(id).subscribe((blogItems) => {
        if (blogItems.length === 0) {
          this.router.navigateByUrl(`/${AppRoutes.BLOGITEMS}`);
        } else {
          this.blogItem = blogItems[0];
        }
      });
      return;
    }

    this.router.navigateByUrl(`/${AppRoutes.BLOGITEMS}`);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
